<p-toast [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }" [position]="position()"
         [styleClass]="styleClass()">

  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2 relative">
                <span class="font-bold">
                    {{ message.summary }}
                </span>
      </div>
      <div class="font-medium text-sm sm:text-lg my-2">
        {{ message.detail }}
      </div>

      <img class="absolute toast-bg-image" src="/assets/images/shared/toast-{{toastSeverity()}}-bg.png"/>
    </div>
  </ng-template>
</p-toast>
